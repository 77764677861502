import styled from 'styled-components/macro';

export const WidgetContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 20px;
  width: 64px;
  height: 64px;
  right: ${({ theme }) => theme.right};
  left: ${({ theme }) => theme.left};
  background-color: ${({ theme }) => theme.themeColor};
  border-color: ${({ theme }) => theme.themeColor};
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  color: white;
  font-size: 24px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.4s ease-out;
  transform: ${(props) => (props.chatActive ? 'rotate(-90deg)' : 'rotate(0deg)')};
  z-index: 5000;

  @media (max-width: 480px), (max-height: 690px) {
    bottom: ${(props) => (props.chatActive ? '-100px' : '20px')};
  }

  @media (max-width: 480px) {
    right: ${({ theme }) => (theme.positionIsRight ? '10px' : 'none')};
    left: ${({ theme }) => (theme.positionIsRight ? 'none' : '10px')};
  }
`;

export const Avatar = styled.img`
  width: 60px !important;
  height: 60px !important;
  border-radius: 50% !important;
`;

export const WidgetLetter = styled.div`
  font-size: 36px;
`;

export const CtaMessageContainer = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 15px;
  position: fixed;
  right: ${({ theme }) => (theme.positionIsRight ? '100px' : 'none')};
  left: ${({ theme }) => (theme.positionIsRight ? 'none' : '100px')};
  bottom: 33px;
  box-shadow: 1px 1px 10px lightgray;
  z-index: 5000;
  max-width: 200px;

  &:after {
    position: fixed;
    width: 0;
    content: '';
    border: 10px solid transparent;
    border-left-color: white;
    border-right: 0;
    right: ${({ theme }) => (theme.positionIsRight ? '95px' : 'none')};
    left: ${({ theme }) => (theme.positionIsRight ? 'none' : '95px')};
    transform: ${({ theme }) => (theme.positionIsRight ? 'none' : 'scaleX(-1)')};
    bottom: 42px;
  }
`;

export const Unread = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -5px;
  right: -5px;
  color: white;
  font-size: 14px;
  font-weight: normal;
  box-shadow: 2px 2px 6px gray;
`;
