import { HiOutlineRefresh } from 'react-icons/hi';
import { GrClose } from 'react-icons/gr';
import { ChatHeaderContainer, AvatarContainer, AvatarImage, Title, IconsContainer, Icon } from './header.styled';

const ChatHeader = ({ settings, setChatActive, endChat }) => {
  const endChatHandler = () => {
    if (window.confirm('Are you sure ending this chat session and start a new one?')) {
      endChat();
    }
  };

  return (
    <ChatHeaderContainer>
      <AvatarContainer>
        {settings?.avatar !== 'disabled' && <AvatarImage alt="avatar" src={settings?.avatar} />}
        <Title>{settings?.title}</Title>
      </AvatarContainer>
      <IconsContainer>
        <Icon onClick={endChatHandler} title="new chat">
          <HiOutlineRefresh />
        </Icon>
        <Icon onClick={() => setChatActive(false)} title="minimize">
          <GrClose />
        </Icon>
      </IconsContainer>
    </ChatHeaderContainer>
  );
};

export default ChatHeader;
