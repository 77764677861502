import styled from 'styled-components/macro';

export const BodyContainer = styled.div`
  padding: 20px;
  overflow-x: hidden;
  overflow-y: auto;
  height: 450px;
  margin: 5px 0px;
  scroll-behavior: smooth;
  transition: all 4s ease-in-out;
  z-index: 5000;

  @media (max-width: 480px) {
    height: calc(100vh - 140px);
  }
`;

export const BotMessageContainer = styled.div`
  width: 100%;
  margin: 5px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

export const BotAvatarContainer = styled.div`
  display: ${({ theme }) => (theme.avatarDisabled ? 'none' : 'block')};
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  overflow: hidden;
  border: 1px solid #cccccc;
`;

export const AgentAvatarContainer = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  border: 1px solid #a8d38d;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #546a46;
`;

export const Avatar = styled.img`
  width: 30px !important;
  height: 30px !important;
  border-radius: 50% !important;
`;

export const BotMessage = styled.div`
  background-color: #eaeaea;
  border: 1px solid #cccccc;
  width: ${(props) => (props.noAvatar ? '90%' : '75%')};
  padding: 20px 10px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 1px;
  /* overflow-wrap: break-word; */
  overflow-x: auto;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;

export const AgentMessage = styled.div`
  background-color: #d1ffbd;
  border: 1px solid #a8d38d;
  width: ${(props) => (props.noAvatar ? '90%' : '75%')};
  padding: 20px 10px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 1px;
  overflow-wrap: break-word;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;

export const UserMessageContainer = styled.div`
  width: 100%;
  margin: 5px;
  margin-bottom: 15px;
`;

export const UserMessage = styled.div`
  width: 65%;
  float: right;
  padding: 20px 10px;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  overflow-wrap: break-word;
  background-color: ${({ theme }) => theme.themeColor};
  margin-bottom: 15px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;
