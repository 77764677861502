import { useQuery, useMutation } from '@tanstack/react-query';
import { ThemeProvider } from 'styled-components';
import { useEffect, useState } from 'react';
import uuid from 'react-uuid';

import { fourZeroOne, fourZeroFour, fiveHundredPlus, startConversationError } from './assets/notifications';
import { playNotification, playError } from './assets/play-notification';
import { sortConversation } from './assets/functions';
import apiRequest from './assets/axios';
import Error from './assets/error';

import WidgetIcon from './components/widget-icon/widget-icon';
import ChatHeader from './components/chat/header';
import ChatFooter from './components/chat/footer';
import ChatBody from './components/chat/body';
import { ChatContainer } from './App.styled';

function App() {
  const [botId] = useState(parseInt(document.getElementById('chat-bot-ai').getAttribute('data-bot')));
  const [widgetSettings, setWidgetSettings] = useState(
    localStorage.getItem(`widgetSettings-${botId}`) && JSON.parse(localStorage.getItem(`widgetSettings-${botId}`))
  );
  const [isTest] = useState(document.getElementById('chat-bot-ai').getAttribute('data-test'));
  const [showInitialMessage2, setShowInitialMessage2] = useState(false);
  const [showInitialMessage, setShowInitialMessage] = useState(false);
  const [liveAgentChatCount, setLiveAgentChatCount] = useState(0);
  const [conversationCount, setConversationCount] = useState(0);
  const [isErrorSending, setIsErrorSending] = useState(false);
  const [conversationId, setConversationId] = useState(null);
  const [enableWidget, setEnableWidget] = useState(false);
  const [messageBucket, setMessageBucket] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [unreadMessage, setUnreadMessage] = useState(0);
  const [conversation, setConversation] = useState([]);
  const [notification, setNotification] = useState('');
  const [chatActive, setChatActive] = useState(false);
  const [isLiveChat, setIsLiveChat] = useState(false);

  // ========================================
  // === GET AND CONFIGURE WIDGET SETTING ===
  // ========================================

  const settingsQuery = useQuery({
    queryKey: ['settings'],
    queryFn: () => apiRequest({ url: `bot/${botId}/widget-settings`, method: 'GET' }).then((res) => res),
    onSuccess: (data) => {
      if (data.status.toString().startsWith('2')) {
        setWidgetSettings(data.data);
        localStorage.setItem(`widgetSettings-${botId}`, JSON.stringify(data.data));
      }
    },
  });

  const activeTheme = {
    themeColor: widgetSettings?.themeColor ? `#${widgetSettings?.themeColor}` : '#3C82F6',
    right: widgetSettings?.position === 'bottom_right' ? '25px' : 'none',
    left: widgetSettings?.position !== 'bottom_left' ? 'none' : '25px',
    avatarDisabled: widgetSettings?.avatar === null || widgetSettings?.avatar === '',
    positionIsRight: widgetSettings?.position === 'bottom_right',
  };

  // =======================================
  // ============= API CALLS ===============
  // =======================================

  const startConversationMutation = useMutation({
    mutationFn: (id) => apiRequest({ url: 'conversation', method: 'POST', data: { botId, sessionId: id } }),
    onSuccess: (data) => {
      if (data.status === 201) {
        setNotification('');
        setIsErrorSending(false);
        setConversationId(data.data.conversation.id);
        localStorage.setItem(`chatBotConversationId-${botId}`, data.data.conversation.id);
        localStorage.setItem(`chatBotConversationToken-${botId}`, data.data.conversationToken);
      } else {
        playError();
        setIsErrorSending(true);
        setNotification(startConversationError(setNotification, startConversationMutation, uuid));
      }
    },
  });

  const fetchConversationMutation = useMutation({
    mutationFn: (id) => apiRequest({ url: `conversation/${id}/messages`, method: 'GET', botId }),
    onSuccess: (data) => {
      let messageQ = messageBucket.map((message) => {
        return { ...message, sender: 'USER' };
      });

      if (data.status === 200) {
        if (conversationCount !== data.data.length) {
          setConversationCount(data.data.length);
          setConversation([...sortConversation(data), ...messageQ]);
        }
        let agentChatCount = data.data.filter((chat) => chat.sender === 'AGENT').length;
        if (liveAgentChatCount !== agentChatCount) {
          setLiveAgentChatCount(agentChatCount);
          playNotification();
        }
      }
    },
  });

  const sendMessageMutation = useMutation({
    mutationFn: (data) => apiRequest({ url: `conversation/${conversationId}/chat`, method: 'POST', data, botId }),
    onSuccess: (data) => {
      if (data.status.toString().startsWith('5')) {
        playError();
        setIsErrorSending(true);
        setNotification(fiveHundredPlus(setNotification, sendMessageMutation, messageBucket));
      } else if (data.status === 401) {
        playError();
        setIsErrorSending(true);
        setNotification(fourZeroOne(setNotification, endChat));
      } else if (data.status === 404) {
        playError();
        setIsErrorSending(true);
        setNotification(fourZeroFour(setNotification, sendMessageMutation, messageBucket));
      } else {
        setIsErrorSending(false);
        setNotification('');
        setConversation([...conversation, { sender: 'MODEL', message: data.data.reply }]);
        fetchConversationMutation.mutate(conversationId);
        if (!chatActive) {
          setUnreadMessage((prev) => prev + 1);
        }
        !chatActive && playNotification();
        setMessageBucket(messageBucket.splice((0, 1)));
      }
      setShowLoading(false);
    },
  });

  const endConversationMutation = useMutation({
    mutationFn: (id) => apiRequest({ url: `conversation/${id}/end`, method: 'GET', botId }),
  });

  const checkActiveChatMutation = useMutation({
    mutationFn: (id) => apiRequest({ url: `conversation/${id}`, method: 'GET', botId }),
    onSuccess: (data) => setIsLiveChat(data.data.liveChat),
  });

  // =======================================
  // ===== INITIAL LOAD USE EFFECT =========
  // =======================================

  useEffect(() => {
    // get conversation and conversation id
    let storedConversationID = localStorage.getItem(`chatBotConversationId-${botId}`);
    if (storedConversationID) {
      setConversationId(storedConversationID);
      fetchConversationMutation.mutate(storedConversationID);
    } else {
      setConversation([]);
    }
    // check internet connectivity
    if (!navigator.onLine) setNotification('You are offline');
    window.addEventListener('online', () => {
      setNotification('');
    });

    window.addEventListener('offline', () => {
      setNotification('You have lost your internet connection.');
    });
    // eslint-disable-next-line
  }, []);

  // =======================================
  // ===== START NEW CHAT SESSION ==========
  // =======================================

  const displayInitialMessage = async () => {
    let message1 = widgetSettings?.welcomeMessage;
    let message2 = widgetSettings?.welcomeMessage2;

    if (!showInitialMessage && message1 && message1.length > 0) {
      await new Promise((res) =>
        setTimeout(() => {
          setShowLoading(true);
          res();
        }, 500)
      );

      await new Promise((res) =>
        setTimeout(() => {
          setShowLoading(false);
          setShowInitialMessage(true);
          res();
        }, message1.length * 30)
      );
    }

    if (!showInitialMessage2 && message2 && message2.length > 0) {
      await new Promise((res) =>
        setTimeout(() => {
          setShowLoading(true);
          res();
        }, 500)
      );

      await new Promise((res) =>
        setTimeout(() => {
          setShowLoading(false);
          setShowInitialMessage2(true);
          res();
        }, message2.length * 30)
      );
    }
  };

  const endChat = async () => {
    conversationId && endConversationMutation.mutate(conversationId);
    setShowInitialMessage2(false);
    setShowInitialMessage(false);
    setIsErrorSending(false);
    setLiveAgentChatCount(0);
    setConversationCount(0);
    setConversationId(null);
    setMessageBucket([]);
    setConversation([]);
    setNotification('');
    await new Promise((res) =>
      setTimeout(() => {
        setConversationId(null);
        localStorage.removeItem(`chatBotConversationId-${botId}`);
        localStorage.removeItem(`chatBotConversationToken-${botId}`);
        displayInitialMessage();
        res();
      }, 500)
    );
  };

  useEffect(() => {
    chatActive && !conversationId && displayInitialMessage();
    // eslint-disable-next-line
  }, [chatActive, showInitialMessage, showInitialMessage2]);

  // =======================================
  // ====== SEND NEW CHAT MESSAGE ==========
  // =======================================

  const addNewMessage = (message) => {
    !conversationId && messageBucket.length === 0 && startConversationMutation.mutate(uuid());
    setMessageBucket([...messageBucket, { message }]);
    setConversation([...conversation, { sender: 'USER', message }]);
  };

  useEffect(() => {
    let delay = setTimeout(() => {
      if (conversationId && messageBucket.length !== 0 && !showLoading && !isErrorSending) {
        !isLiveChat && setShowLoading(true);
        sendMessageMutation.mutate(messageBucket[0]);
      }
    }, 500);

    return () => clearTimeout(delay);
    // eslint-disable-next-line
  }, [showLoading, messageBucket, conversationId]);

  // =======================================
  // ========= CHECK URL FILTER ============
  // =======================================

  const checkUrlFilter = (appearOn, urlFilter) => {
    if (isTest === 'true' || appearOn === 'all_pages') {
      setEnableWidget(true);
    } else {
      let currentUrl = window.location.href;
      let index = currentUrl.indexOf('?');
      let urlFilterList = urlFilter && urlFilter.split(',');
      let urlWithoutParams = index > -1 ? currentUrl.substring(0, index) : currentUrl;

      if (urlFilterList?.includes(urlWithoutParams)) {
        setEnableWidget(true);
      } else {
        setEnableWidget(false);
      }
    }
  };

  useEffect(() => {
    widgetSettings && checkUrlFilter(widgetSettings.appearOn, widgetSettings.urlFilter);
    // eslint-disable-next-line
  }, [widgetSettings]);

  // =======================================
  // ===== LIVE CHAT FETCH MESSAGE =========
  // =======================================

  useEffect(() => {
    let fetchInterval;
    if (widgetSettings?.hasHelpDesk && conversationId) {
      fetchInterval = setInterval(() => {
        checkActiveChatMutation.mutate(conversationId);
      }, 10000);
    }
    return () => {
      if (fetchInterval) {
        clearInterval(fetchInterval);
      }
    };
    // eslint-disable-next-line
  }, [widgetSettings?.hasHelpDesk, conversationId]);

  useEffect(() => {
    let fetchInterval;
    if (widgetSettings?.hasHelpDesk && isLiveChat && conversationId) {
      fetchInterval = setInterval(() => {
        fetchConversationMutation.mutate(conversationId);
      }, 5000);
    }
    return () => {
      if (fetchInterval) {
        clearInterval(fetchInterval);
      }
    };
    // eslint-disable-next-line
  }, [widgetSettings?.hasHelpDesk, isLiveChat, conversationId]);

  // =======================================
  // ========= PAGE BEGINS HERE ============
  // =======================================

  if (settingsQuery.isLoading || !enableWidget) return null;

  if (settingsQuery.isError) {
    console.log('ERROR: ', settingsQuery.error);
    return (
      <ThemeProvider theme={activeTheme}>
        <Error />
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={activeTheme}>
      <ChatContainer chatActive={chatActive}>
        <ChatHeader setChatActive={setChatActive} settings={widgetSettings} endChat={endChat} />
        <ChatBody
          showInitialMessage2={showInitialMessage2}
          showInitialMessage={showInitialMessage}
          conversationCount={conversationCount}
          conversation={conversation}
          showLoading={showLoading}
          chatActive={chatActive}
          settings={widgetSettings}
        />
        <ChatFooter addNewMessage={addNewMessage} notification={notification} settings={widgetSettings} />
      </ChatContainer>
      <WidgetIcon
        setUnreadMessage={setUnreadMessage}
        setChatActive={setChatActive}
        settings={widgetSettings}
        unreadMessage={unreadMessage}
        chatActive={chatActive}
      />
    </ThemeProvider>
  );
}

export default App;
