import { useEffect, useState } from 'react';
import { GrClose } from 'react-icons/gr';

import { playNotification } from '../../assets/play-notification';

import { WidgetContainer, Avatar, WidgetLetter, CtaMessageContainer, Unread } from './widget-icon.styled';

const WidgetIcon = ({ setUnreadMessage, unreadMessage, setChatActive, chatActive, settings }) => {
  const [showCta, setShowCta] = useState(false);

  useEffect(() => {
    let ctaShown = localStorage.getItem(`chatBotCtaShown-${settings.id}`);
    setTimeout(() => {
      if (!ctaShown) {
        setShowCta(true);
      } else {
        setShowCta(false);
      }
    }, 10000);
    localStorage.setItem(`chatBotCtaShown-${settings.id}`, true);
  }, [showCta, chatActive, settings.id]);

  const getInitials = (name) => {
    const words = name.split(' ');
    let initials = '';
    words.forEach((word) => {
      const firstLetter = word.charAt(0).toUpperCase();
      if (initials.length < 2) initials += firstLetter;
    });
    return initials;
  };

  const onClickHandler = () => {
    setChatActive((prev) => !prev);
    showCta && setShowCta(false);
    setUnreadMessage(0);
  };

  return (
    <>
      <WidgetContainer chatActive={chatActive} onClick={onClickHandler}>
        {unreadMessage > 0 && <Unread>{unreadMessage}</Unread>}
        {chatActive ? (
          <GrClose />
        ) : (
          <>
            {settings?.avatar === 'disabled' || settings?.avatar === '' ? (
              <WidgetLetter>{getInitials(settings?.title)}</WidgetLetter>
            ) : (
              <Avatar src={settings?.avatar} alt="avatar" />
            )}
          </>
        )}
      </WidgetContainer>
      {showCta && !chatActive && settings?.ctaMessage && (
        <>
          {playNotification()}
          <CtaMessageContainer>
            <p>{settings?.ctaMessage}</p>
          </CtaMessageContainer>
        </>
      )}
    </>
  );
};

export default WidgetIcon;
